export default class ApiEndPoint {
  static brandInsurance = "/brand-insurance";
  static search = "/search";
  static upload = "/upload";
  static bulk = "/bulk";
  static history = "/history";
  static otp = "/otp";
  static send = "/send";
  static verify = "/verify";
  static auth = "/auth";
  static user = "/user";
  static logout = "/logout";
  static download = "/download";
}
