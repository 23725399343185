// eslint-disable-next-line import/no-cycle
// import { getCookie } from './general';
// import { useAccessControl } from './hooks';

export const USER_ROLES = {
  USER: "USER",
  SBP_USER: "SBP_USER",
};

// eslint-disable-next-line react-hooks/rules-of-hooks
// const accessControl = useAccessControl();

export const DEFAULT_LANDING_ROUTE = () => {
  const homepage = "/brand-insurance-listing";

  return homepage;
};

export const ERROR_CODES = {
  /* list of error codes that can be part of the api responses */
  VALIDATION_ERRORS: [406],
  REGULAR_ERRORS: [400, 401, 403, 404, 500],
};

export const ACCEPTED = "ACCEPTED";
export const VISIT_INITIATED = "VISIT_INITIATED";
export const VISIT_REACHED_LOCATION = "VISIT_REACHED_LOCATION";
export const VISIT_DONE = "VISIT_DONE";
export const CANCELLED = "CANCELLED";
export const RESCHEDULED = "RESCHEDULED";
export const COMPLETED = "COMPLETED";
export const CREATED = "CREATED";

export const CONSTANT = {
  Order_ID: "Order ID",
  HSP_Code: "HSP Code",
  Service_ID: "Service ID",
  Customer_Mobile_Number: "Customer Mobile Number",
  Engineer_Mobile_Number: "Engineer Mobile Number",
};

export const GOOGLE_EVENTS = {
  LOGIN: "login",
  LOGOUT: "logout",
  SEARCH: "search",
  FILTER: "filter",
  OPEN: "open",
  APPLY: "apply",
  TYPE: "type",
  CLOSE: "close",
  TAB: "tab",
  AUTH: "auth",
  USAGE: "usage",
  REFRESH: "refresh",
  PAGE_COUNT: "page_count",
  PAGINATION: "pagination",
  PAGE_CHANGE: "page_change",
  SUCCESSFUL: "successful",
  FAILED: "failed",
  OTP: "otp",
  EXCEED: "exceed",
  UNAUTHORIZED: "unauthorized",
  ACCESS: "access",
};

export const DEFAULT_FILTER_OPTIONS = [
  { value: "Yes", key: true },
  { value: "No", key: false },
];

// eslint-disable-next-line
export default {
  DEFAULT_LANDING_ROUTE,
  ERROR_CODES,
  DEFAULT_FILTER_OPTIONS,
  GOOGLE_EVENTS,
  USER_ROLES,
};
