import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Routers from "./router";
import Loader from "./component/common/Loader";
import Layout from "./component/Layout/layout";
import Logo from "./assets/images/resqLogo.png";
import { getCookie } from "./helpers/general";
import AlertMessage from "./component/common/AlertMessage";
import { setRequestStatus } from "./redux/slices/generalSlice";
// import { useGApageview } from "./helpers/hooks";

import "./App.css";

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [userAuth, setUserAuth] = React.useState(false);
  const {
    general: { requestStatus },
  } = useSelector((state) => state);

  useEffect(() => {
    const auth = getCookie("userAuth");
    setUserAuth(Boolean(auth));
  }, [location]);

  // useGApageview();

  return (
    <div className="App">
      <Loader />
      <Layout logo={Logo} profilePicture="" protectedRoute={userAuth}>
        <Routers />
        {requestStatus.show &&
          (requestStatus.header || requestStatus.description) && (
            <AlertMessage
              header={requestStatus.header}
              description={requestStatus.description}
              variantType={requestStatus.error ? "default" : "success"}
              setShowSnackbar={() =>
                dispatch(setRequestStatus({ show: false }))
              }
            />
          )}
      </Layout>
    </div>
  );
};

export default App;
