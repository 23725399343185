import React from "react";

const defaultHeaderConfig = {
  logo: false,
  home: false,
  title: "",
};
export const privateRoutes = [
  {
    Path: "/brand-insurance-listing",
    Component: React.lazy(() => import("../container/BrandInsurance")),
    meta: {
      name: "Insurance Listing",
    },
    headerConfig: {
      ...defaultHeaderConfig,
      home: false,
      logo: true,
    },
    id: "brand-insurance-listing",
  },
  {
    Path: "/file-upload",
    Component: React.lazy(() => import("../container/InsuranceUpload")),
    meta: {
      name: "File Upload",
    },
    headerConfig: {
      ...defaultHeaderConfig,
      home: false,
      logo: true,
    },
    id: "file-upload",
  },
];

export const publicRoutes = [
  {
    Path: "/login",
    Component: React.lazy(() => import("../container/Login")),
    meta: {
      name: "Login page",
    },
    headerConfig: {
      ...defaultHeaderConfig,
      home: false,
      logo: true,
    },
    id: "login",
  },
  {
    Path: "/termsandconditions",
    Component: React.lazy(() => import("../container/TermsAndConditions")),
    meta: {
      name: "TnC page",
    },
    headerConfig: {
      ...defaultHeaderConfig,
      home: false,
      logo: false,
    },
    id: "tnc",
  },
];

// eslint-disable-next-line
export default { privateRoutes, publicRoutes };
