import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  insurancesData: {},
};

export const insurancesSlice = createSlice({
  name: "insurances",
  initialState,
  reducers: {
    getInsurancesData: (state, action) => {
      state.insurancesData = action.payload;
    },
  },
});

export const { getInsurancesData } = insurancesSlice.actions;

const insurancesReducer = insurancesSlice.reducer;

export default insurancesReducer;
