import axios from "axios";
// eslint-disable-next-line import/no-cycle
import {
  apiErrorResponse,
  DispatchNotification,
  deleteAllCookies,
  ForceLogout,
} from "../helpers/general";

const baseUrl = window.location.origin + "/api";

const client = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
  headers: {},
  timeout: 50000,
});

const request = ({
  method,
  url,
  headers = {},
  data = {},
  isErrorShow = true,
}) => {
  const onSuccess = (response) => response;
  const onError = (error) => {
    const errorMessage = apiErrorResponse(error?.response ?? {});

    /* application should get logged out and clear the cookies on 401 and 403 error response*/

    const routeTo = "login";
    if (error.response.status === 403 || error.response.status === 401) {
      deleteAllCookies();
      ForceLogout();
      window.location = `${window.location.origin}/${routeTo}`;
    }

    console.log("isErrorShow", isErrorShow);
    if (isErrorShow)
      DispatchNotification(errorMessage ?? "Something Went Wrong");
    return Promise.reject(error);
  };

  const payload = {
    method,
    url,
    headers: {
      ...headers,
    },
    data,
  };

  return client(payload).then(onSuccess).catch(onError);
};

const Api = {
  get: (url, params, headers) => {
    return request({
      method: "GET",
      url,
      headers,
      params,
    });
  },
  post: (url, data, headers, responseType, isErrorShow) => {
    console.log(url, data, headers, responseType, isErrorShow);
    return request({
      method: "POST",
      url,
      headers,
      data,
      responseType,
      isErrorShow,
    });
  },
  put: (url, data, headers) => {
    return request({
      method: "PUT",
      url,
      headers,
      data,
    });
  },
  delete: (url, headers) => {
    return request({
      method: "DELETE",
      url,
      headers,
    });
  },
};

export const AuthApi = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
  headers: {
    Authorization: `Basic ${process.env.REACT_APP_BASIC_TOKEN}`,
  },
});

AuthApi.interceptors.response.use(
  (res) => res,
  (error) => {
    const errorMessage = apiErrorResponse(error?.response ?? {});
    DispatchNotification(errorMessage ?? "Something Went Wrong");
    return Promise.reject(error);
  }
);

export default Api;
