import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getCookie } from "../helpers/general";

export const LoginRoute = () => {
  const userAuth = getCookie("userAuth");
  return !userAuth ? <Outlet /> : <Navigate to="/" />;
};

export const ProtectedRoute = () => {
  const userAuth = getCookie("userAuth");
  return userAuth ? <Outlet /> : <Navigate to="/login" />;
};

// eslint-disable-next-line
export default { LoginRoute, ProtectedRoute };
