import refresh from "./refresh.svg";
import filter from "./filter.svg";
import dropdownArrow from "./dropdownArrow.svg";
import upArrow from "./upArrow.svg";
import exportIcon from "./export.svg";
import errorIcon from "./errorIcon.svg";
import bulkBackIcon from "./bulkBackIcon.svg";
import backIcon from "./backIcon.svg";
import bulkFrontIcon from "./bulkFrontIcon.svg";
import frontIcon from "./frontIcon.svg";
import dateIcon from "./dateIcon.svg";
import selectSearchIcon from "./selectSearchIcon.svg";
import selectIcon from "./selectIcon.svg";
import backArrowIcon from "./backArrowIcon.svg";
import closeIcon from "./closeIcon.svg";
import serviceBookings from "./serviceBookings.svg";
import hamburgerToggler from "./hamburgerToggler.svg";
import editIcon from "./editIcon.svg";
import uploadToCloud from "./uploadToCloud.svg";
import downloadFromCloud from "./downloadFromCloud.svg";

const Images = {
  dropdownArrow,
  exportIcon,
  errorIcon,
  upArrow,
  bulkBackIcon,
  backIcon,
  frontIcon,
  bulkFrontIcon,
  dateIcon,
  selectSearchIcon,
  selectIcon,
  backArrowIcon,
  closeIcon,
  refresh,
  filter,
  serviceBookings,
  hamburgerToggler,
  editIcon,
  uploadToCloud,
  downloadFromCloud,
};

export default Images;
