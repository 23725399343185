import styled from '@emotion/styled';

const Body = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 72px;
`;
const Children = styled('div')`
  width: ${(props) => `calc(100vw - ${props.sideBarWidth})`};
  margin-left: ${(props) => (props.sideBar ? props.sideBarWidth : '0px')};
`;
const LayoutComponent = {
  Body,
  Children,
};

export default LayoutComponent;
