import { combineReducers, configureStore } from "@reduxjs/toolkit";
import generalReducer from "../slices/generalSlice";
import fileUploadsReducer from "../slices/fileUploadsSlice";
import insurancesReducer from "../slices/insurancesSlice";
import userReducer from "../slices/userSlice";

const rootReducer = combineReducers({
  general: generalReducer,
  fileUploads: fileUploadsReducer,
  user: userReducer,
  insurances: insurancesReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
