import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fileUploadsData: [],
};

export const fileUploadsSlice = createSlice({
  name: "fileUploads",
  initialState,
  reducers: {
    getFileUploadsData: (state, action) => {
      state.fileUploadsData = action.payload;
    },
  },
});

export const { getFileUploadsData } = fileUploadsSlice.actions;

const fileUploadsReducer = fileUploadsSlice.reducer;

export default fileUploadsReducer;
