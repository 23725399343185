import { showLoader, stopLoader } from "../redux/slices/generalSlice";
import { setMobileNumber } from "../redux/slices/userSlice";
import Api, { AuthApi } from ".";
import ApiEndPoint from "./ApiEndpoint";
import { deleteAllCookies, setCookie } from "../helpers/general";

export const sendOtp = (mobile) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const url = ApiEndPoint.otp + ApiEndPoint.send;
    const userObject = {
      mobile,
      country_code: "+91",
    };
    const { data } = await AuthApi.post(url, userObject);
    dispatch(stopLoader());
    return data;
  } catch (e) {
    const res = e.response;
    dispatch(stopLoader());
    return res;
  }
};

export const verifyOtp =
  (otpData, successMethod = () => null, errorMethod = () => null) =>
  async (dispatch) => {
    try {
      dispatch(showLoader());
      const url = ApiEndPoint.otp + ApiEndPoint.verify;
      const body = { ...otpData };
      const {
        data: { data },
      } = await AuthApi.post(url, body);
      setCookie("userAuth", "true", 1);
      setCookie("userRoles", JSON.stringify(data), 1);
      successMethod();
      dispatch(stopLoader());
      return data;
    } catch (e) {
      const error = e.response;
      dispatch(stopLoader());
      errorMethod(
        error?.data?.data?.errorMessage ??
          "Something went wrong. Please try again..!"
      );
      return error;
    }
  };

export const logout =
  (successMethod = () => null) =>
  async (dispatch) => {
    try {
      dispatch(showLoader());
      const url = ApiEndPoint.auth + ApiEndPoint.user + ApiEndPoint.logout;
      const { data } = await Api.post(url);
      setCookie("userAuth", "false", -1);
      deleteAllCookies();
      dispatch(setMobileNumber(""));
      successMethod();
      dispatch(stopLoader());
      return data;
    } catch (e) {
      dispatch(stopLoader());
      return e?.response;
    }
  };

// eslint-disable-next-line
export default { sendOtp, verifyOtp, logout };
