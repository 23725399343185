import styled from '@emotion/styled';

const appBarHeight = 72;

const screenHeight = window.innerHeight - appBarHeight;

const Wrapper = styled('div')`
  position: fixed;
  left: 0;
  top: ${appBarHeight};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: ${(props) => props.sideBarWidth};
  height: ${screenHeight}px;
  background: var(--color-primary-background);
  z-index: 2;
  transition: width 400ms;
`;
const Container = styled('div')`
  display: flex;
  width: 100%;
`;
const Divider = styled('div')`
  height: 1px;
  width: 100%;
  background: var(--color-primary-grey-40);
  border-radius: 80px;
`;
const MenuList = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 38px 20px;
`;

const MenuItem = styled('div')`
  display: flex;
  align-items: center;
  letter-spacing: -0.005em;
  line-height: 24px;
  cursor: pointer;
  padding: 16px 0px;
`;
const Label = styled('span')`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: ${(props) =>
    props.active ? 'var(--color-primary-60)' : 'var(--color-primary-grey-80)'};
  padding-left: 20px;
  &: hover {
    color: var(--color-primary-60);
  }
  white-space: nowrap;
`;
const Icon = styled('img')``;

const MenuToggler = styled('img')`
  position: absolute;
  right: -10px;
  top: 20px;
  cursor: pointer;
  transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const SidebarComponent = {
  Wrapper,
  Container,
  Divider,
  MenuList,
  MenuItem,
  Label,
  Icon,
  MenuToggler,
};

export default SidebarComponent;
