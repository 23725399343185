import Images from "../assets/images/index";
// import { USER_ROLES } from "../helpers/constant";

const { serviceBookings } = Images;
const sideBarMenuConfig = [
  {
    id: 1,
    title: "Insurance Listing",
    icon: serviceBookings,
    route: "/brand-insurance-listing",
    isActive: false,
    // roles: USER_ROLES.USER,
  },
  {
    id: 2,
    title: "File Upload",
    icon: serviceBookings,
    route: "/file-upload",
    isActive: false,
    // roles: USER_ROLES.USER,
  },
];

export default sideBarMenuConfig;
